import React from "react";
import { getContactHref } from "../../utils";
import styles from "./Author.module.scss";
import { useSiteMetadata } from "../../hooks";

const Author = () => {
	const { author } = useSiteMetadata();

	return (
		<div className={styles["author"]}>
			<p>{author.bio}</p>
		</div>
	);
};

export default Author;
